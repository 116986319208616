import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { InteractionService } from "app/services/interaction.service";
import { LanguageService } from "app/services/language.service";

@Component({
    selector: 'app-avatar-video-presenter',
    templateUrl: './avatar-video.component.html',
    styleUrl: './avatar-video.component.scss'
})
export class AvatarVideoComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('avatarVideo') videoAnimation!: ElementRef;
    canVideoBePlayed: boolean = false;
    avatarBackground: any;
    langVideos: any[] = [];

    backgroundSubscription!: Subscription;
    videoSubscription!: Subscription;

    constructor(
        private config: ConfigService,
        private interaction: InteractionService,
        private language: LanguageService,
        private cdr: ChangeDetectorRef
    ) {}

     ngOnInit(): void {
        this.backgroundSubscription = this.config.getPresenter().subscribe(background => {
            if (background) { this.avatarBackground = background; }
        });

        this.videoSubscription = this.config.getLangVideos().subscribe(async videos => {
            this.langVideos = videos;
            await this.prepareVideoPlayback("idle");
            this.playVideo();
        });
    }

    async ngAfterViewInit() {
        if (!this.config.getFirstTime()) {
            await this.prepareVideoPlayback("welcome");
            this.config.setFirstOpen();
            this.interaction.isVideoPlaying.next(true);
        } else {
            await this.prepareVideoPlayback("idle");
        }

        // waiting to get loaded
        await new Promise((resolve, _) => { // TODO: unsafe, needs to be reviewed
            setInterval(() => {
                if (this.canVideoBePlayed) resolve("");
            }, 100);
        });

        this.playVideo();
    }

    playVideo () {
        (this.getVideoElement()).play();
    }

    getVideo(video: string) {
        return this.langVideos.find(lv => lv.type === video);
    }

    getVideoElement() {
        return this.videoAnimation?.nativeElement;
    }

    async prepareVideoPlayback (video: string) {
        const element = this.getVideoElement();
        const videoObject = this.getVideo(video)

        if (!videoObject || videoObject.blob == null) {
            throw new Error(`${video}: object is null or doesn't exists!`);
        }
        
        if(element) {
            element.src = videoObject.source;

            await new Promise(resolve => {
                element.onloadedmetadata = resolve;
            });
        }
    }

    // events
    onLoadedMetadata() {
        this.canVideoBePlayed = true;
    }

    async onEnded () {
        await this.prepareVideoPlayback("idle");
        this.playVideo();
         this.interaction.isVideoPlaying.next(false);
    }

    ngOnDestroy(): void {
        this.videoSubscription.unsubscribe();
        this.backgroundSubscription.unsubscribe();
    }
}
